import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  Badge,
  Box,
  Flex,
  Text,
  VStack,
} from "@chakra-ui/react"
import {
  AppEntity,
  DeploymentEntity,
  GlobalDeploymentSettingsEntity,
  SettingsEntity,
} from "@jackfruit/common"
import Body from "components/Body"
import FormSectionHeader from "components/FormSectionHeader"
import Navbar, { NavbarConfig } from "components/Navbar/Navbar"
import { useAuthState } from "hooks/useAuthState"
import { useConfigurationState } from "hooks/useConfigurationState"
import { useEntity } from "hooks/useEntity"
import { useFindOneEntity } from "hooks/useFindOneEntity"
import { useHasOneResource } from "hooks/useHasOneResource"
import { useResourceForm } from "hooks/useResourceForm"
import { RouteParams } from "navigation/RouteParams"
import React, { useMemo } from "react"
import { FiArrowLeft } from "react-icons/fi"
import { useHistory, useParams } from "react-router-dom"
import DeploymentConfig from "./components/DeploymentConfig"
import AppDeploymentForm from "./forms/AppDeploymentForm"

interface Props {}

const AppDeploymentUpgradeScreen: React.FC<Props> = () => {
  const history = useHistory()
  const { user } = useAuthState()
  const { deploymentId } = useParams<RouteParams>()
  const { configuration } = useConfigurationState()
  const { appId } = useParams<RouteParams>()
  const { data: app, isSuccess: hasLoadedApp } = useEntity<AppEntity>(
    "apps",
    appId
  )

  const { data: deploymentToUpgrade, isLoading: isLoadingDeploymentToUpgrade } =
    useEntity<DeploymentEntity>("deployments", deploymentId)

  const {
    data: globalDeploymentSettings,
    isLoading: isLoadingGlobalDeploymentSettings,
  } = useFindOneEntity<GlobalDeploymentSettingsEntity>(
    "global-deployment-settings",
    {
      query: {},
    }
  )

  const { entity: settings, isLoading: isLoadingSettings } =
    useHasOneResource<SettingsEntity>("settings", "appId", parseInt(appId))

  const {
    entity,
    isCreating,
    isLoading: isLoadingDeployment,
    onSubmit,
  } = useResourceForm<DeploymentEntity>("deployments")

  const { location } = useHistory()

  const goBackPath = useMemo(() => {
    const path = location.pathname.split("/").slice(0, -1).join("/")
    return `${path}/general`
  }, [location])

  const navbarConfig: NavbarConfig = {
    items: [
      {
        type: "link",
        to: goBackPath,
        label: "Back",
        icon: <FiArrowLeft />,
        canActivate: false,
      },
      {
        type: "separator",
      },
      {
        type: "content",
        content: (
          <Flex ml={3} alignItems="center">
            <Text>{`Upgrade Code for Deployment #${deploymentToUpgrade.id}`}</Text>
          </Flex>
        ),
      },
    ],
  }

  const isLoading =
    isLoadingDeployment ||
    !hasLoadedApp ||
    isLoadingSettings ||
    isLoadingGlobalDeploymentSettings ||
    isLoadingDeploymentToUpgrade

  if (isLoading) {
    return null
  }

  const canUpgrade = deploymentToUpgrade.status === "complete"

  const gitReference =
    entity?.gitReference || globalDeploymentSettings?.defaultVersion || ""

  entity.gitReference = gitReference
  entity.liveDeploymentTestTags =
    configuration.deployment.liveDeploymentTestTags || []
  entity.testTags = configuration.deployment.testTags || ""

  const enhancedOnSubmit = async (data: any) => {
    const updatedData = {
      appId: appId,
      userId: user.id,
      status: "pending",
      type: deploymentToUpgrade.type,
      url: deploymentToUpgrade.url,
      cloudFrontDistributionId: deploymentToUpgrade.cloudFrontDistributionId,
      s3BucketName: deploymentToUpgrade.s3BucketName,
      lambdaAbVariantId: deploymentToUpgrade.lambdaAbVariantId,
      generatedConfig: deploymentToUpgrade.generatedConfig,
      ...data,
    }

    const deployment = await onSubmit(updatedData)
    history.push(`/admin/apps/${appId}/deployments/${deployment.id}`)
  }

  if (!canUpgrade) {
    return (
      <Body>
        <Alert status="error" borderRadius="md">
          <AlertIcon />
          <AlertDescription>
            This deployment cannot be upgraded. Status is{" "}
            <strong>{deploymentToUpgrade.status}</strong>.
          </AlertDescription>
        </Alert>
      </Body>
    )
  }

  return (
    <VStack spacing={4} alignItems="start">
      <Navbar config={navbarConfig} />
      <Body>
        <Flex direction="column" gap={4} width="2xl">
          <FormSectionHeader>Configuration</FormSectionHeader>
          <Box>
            <Alert status="info" borderRadius="md">
              <AlertIcon />
              <AlertDescription>
                You are about to upgrade <strong>"{app.displayName}"</strong>{" "}
                currently running on{" "}
                <Badge textTransform="lowercase">
                  {deploymentToUpgrade.gitReference}
                </Badge>
                . This will upgrade the code only. Any content change will be
                ignored.
              </AlertDescription>
            </Alert>
            <Accordion allowMultiple mt={3}>
              <AccordionItem>
                <AccordionButton justifyContent="space-between" pl={0}>
                  <Text>Config JSON</Text>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  <Box mt={3}>
                    <DeploymentConfig deploymentId={deploymentToUpgrade.id} />
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
          <AppDeploymentForm
            entity={entity}
            settings={settings}
            latestDeployment={deploymentToUpgrade}
            isLoading={isCreating}
            onSubmit={enhancedOnSubmit}
          />
        </Flex>
      </Body>
    </VStack>
  )
}

export default AppDeploymentUpgradeScreen
