import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useTheme,
  VStack,
} from "@chakra-ui/react"
import {
  AppEntity,
  DeploymentEntity,
  LambdaAbVariantEntity,
} from "@jackfruit/common"
import Body from "components/Body"
import Card from "components/Card"
import CompiledChangelogs from "components/Changelogs/CompiledChangelogs"
import DeploymentDuration from "components/DeploymentDuration"
import DeploymentSteps from "components/Deployments/DeploymentSteps"
import DeploymentStatusBadge from "components/DeploymentStatusBadge"
import DeploymentTypeBadge from "components/DeploymentTypeBadge"
import FormSectionHeader from "components/FormSectionHeader"
import Label from "components/Label"
import Navbar, { NavbarConfig } from "components/Navbar/Navbar"
import { format } from "date-fns"
import { useAuthState } from "hooks/useAuthState"
import { useCurrentAppId } from "hooks/useCurrentAppId"
import { useEntity } from "hooks/useEntity"
import { useFindOneEntity } from "hooks/useFindOneEntity"
import { useResourceActions } from "hooks/useResourceActions"
import { RouteParams } from "navigation/RouteParams"
import React, { useEffect, useMemo, useRef, useState } from "react"
import {
  FiArrowLeft,
  FiChevronDown,
  FiGitBranch,
  FiRepeat,
  FiRotateCcw,
  FiXOctagon,
} from "react-icons/fi"
import {
  generatePath,
  Link,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom"
import { useUpdateEffect, useWindowSize } from "react-use"
import { getExtraLines } from "utils/helpers"
import { FitAddon } from "xterm-addon-fit"
import { SearchAddon } from "xterm-addon-search"
import { WebLinksAddon } from "xterm-addon-web-links"
import { XTerm } from "xterm-for-react"
import AppHeaderFavicon from "../AppHeaderFavicon"
import DeploymentConfig from "./components/DeploymentConfig"
import DeploymentTestResultsContainer from "./components/DeploymentTestResultsContainer"

const fitAddon = new FitAddon()
const searchAddon = new SearchAddon()
const weblinksAddon = new WebLinksAddon()

const xtermAddons = [fitAddon, searchAddon, weblinksAddon]

const Row = ({ children }: { children: React.ReactNode }) => {
  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      w="full"
    >
      {children}
    </Flex>
  )
}

interface ActionsProps {
  deployment?: DeploymentEntity
}

const Actions: React.FC<ActionsProps> = ({ deployment }) => {
  const { user } = useAuthState()
  const history = useHistory()
  const match = useRouteMatch()

  const { patch, create } = useResourceActions("deployments")

  if (!deployment) {
    return null
  }

  const {
    id,
    appId,
    generatedConfig,
    comment,
    status,
    progress,
    testTags,
    url,
    gitReference,
    s3BucketName,
    cloudFrontDistributionId,
    type,
  } = deployment

  const onRetry = async () => {
    const newDeployment: any = {
      appId,
      userId: user.id,
      status: "pending",
      progress: 0,
      generatedConfig,
      comment,
      testTags,
      url,
      gitReference,
      s3BucketName,
      cloudFrontDistributionId,
      type,
    }

    const retryDeploymentEntity = await create(newDeployment)

    history.push(
      generatePath(match.path, {
        ...match.params,
        deploymentId: retryDeploymentEntity.id,
      })
    )
  }

  const onCancel = async () => {
    await patch(id, { status: "canceled" })
  }

  const canCancel = ["pending", "deploying"].includes(status) && progress < 80

  const canRetry = ["complete", "failed", "canceled"].includes(status)

  const canUpgrade = ["complete"].includes(status)

  return (
    <HStack>
      {canRetry && (
        <Menu>
          <MenuButton
            as={Button}
            colorScheme="gray"
            size="sm"
            leftIcon={<Icon as={FiRepeat} />}
            rightIcon={<FiChevronDown />}
          >
            Re Deploy
          </MenuButton>
          <MenuList>
            <MenuItem
              as={Link}
              to={`/admin/apps/${appId}/deployments/${deployment.id}/upgrade`}
              icon={<FiGitBranch />}
              isDisabled={!canUpgrade}
            >
              Change Version
            </MenuItem>

            <MenuItem onClick={onRetry} icon={<FiRotateCcw />}>
              Retry Previous
            </MenuItem>
          </MenuList>
        </Menu>
      )}
      {canCancel && (
        <Button
          onClick={onCancel}
          leftIcon={<Icon as={FiXOctagon} />}
          colorScheme="red"
          size="sm"
        >
          Cancel
        </Button>
      )}
    </HStack>
  )
}

interface Props {}

const AppDeploymentDetailsScreen: React.FC<Props> = () => {
  const appId = useCurrentAppId()
  const { width: windowWidth } = useWindowSize()
  const { colors } = useTheme()
  const { deploymentId } = useParams<RouteParams>()
  const [logLines, setLogLines] = useState<string[]>([])
  const xtermRef = useRef<XTerm>(null)
  const { location } = useHistory()

  const { data: entity, isLoading } = useEntity<DeploymentEntity>(
    "deployments",
    deploymentId,
    {
      refetchInterval: 7000,
    },
    {
      query: {
        $select: [
          "id",
          "appId",
          "createdAt",
          "startedAt",
          "finishedAt",
          "testTags",
          "userId",
          "type",
          "status",
          "comment",
          "gitReference",
          "url",
          "progress",
          "testResults",
          "logGroupName",
          "logStreamName",
        ],
      },
    }
  )

  const { data: previousDeployment, isLoading: isLoadingPreviousDeployment } =
    useFindOneEntity<DeploymentEntity>("deployments", {
      query: {
        appId,
        type: "live",
        status: "complete",
        id: {
          $lt: deploymentId,
        },
        $sort: {
          id: -1,
        },
        $select: ["id"],
      },
    })

  const { data: app, isLoading: isLoadingApp } = useEntity<AppEntity>(
    "apps",
    entity?.appId,
    {
      enabled: Boolean(entity?.appId),
    }
  )

  const { data: lambdaAbVariantEntity } = useEntity<LambdaAbVariantEntity>(
    "lambda-ab-variants",
    entity.lambdaAbVariantId,
    {
      enabled: Boolean(entity.lambdaAbVariantId),
    }
  )

  const goBackPath = useMemo(
    () => location.pathname.split("/").slice(0, -2).join("/"),
    [location]
  )

  const navbarConfig: NavbarConfig = {
    items: [
      {
        type: "link",
        to: goBackPath,
        label: "Back",
        icon: <FiArrowLeft />,
        canActivate: false,
      },
      {
        type: "separator",
      },
      {
        type: "content",
        content: (
          <Flex ml={3} alignItems="center">
            <Text>{`Deployment #${entity.id}`}</Text>
          </Flex>
        ),
      },
      {
        type: "content",
        content: (
          <Flex flex={1} justifyContent="flex-end">
            <Actions deployment={entity} />
          </Flex>
        ),
      },
    ],
  }

  useUpdateEffect(() => {
    fitAddon.fit()

    if (!isLoading) {
      const formattedLogLines = entity.logs.map(log => {
        return `${format(log.timestamp, "HH:mm:ss")} > ${log.message}`
      })

      const linesToWrite = getExtraLines(logLines, formattedLogLines)
      linesToWrite.forEach(line => xtermRef.current?.terminal.writeln(line))
      if (linesToWrite.length > 0) {
        xtermRef.current?.terminal.scrollToBottom()
      }

      setLogLines(formattedLogLines)
    }
  }, [entity.logs?.length])

  useEffect(() => {
    fitAddon.fit()
  }, [windowWidth])

  if (isLoading || isLoadingApp || isLoadingPreviousDeployment) {
    return null
  }

  return (
    <VStack spacing={4} alignItems="start" w="full">
      <Navbar config={navbarConfig} />
      <HStack alignItems="start" spacing={4} w="full">
        <VStack>
          <Body variant="transparent">
            <HStack alignItems="stretch" spacing={4}>
              <Flex
                borderRadius="md"
                borderWidth={1}
                padding={4}
                bg="white"
                flex={1}
              >
                <Flex flex={1.5} pr={3} alignItems="start" direction="column">
                  <Flex
                    mb={6}
                    alignItems="center"
                    w="full"
                    borderBottomWidth={1}
                    pb={3}
                  >
                    <AppHeaderFavicon id={app.faviconImageId} size={20} />
                    <VStack alignItems="start" ml={3}>
                      <Text fontWeight="bold">{app.name}</Text>
                      <Text>{entity.gitReference}</Text>
                    </VStack>
                  </Flex>
                  <VStack alignItems="start" spacing={5} w="full">
                    <VStack alignItems="start" w="full">
                      <Heading fontSize="md">Outline</Heading>
                      <Row>
                        <Label>Type</Label>
                        <DeploymentTypeBadge type={entity.type} />
                      </Row>
                      <Row>
                        <Label>Status</Label>
                        <DeploymentStatusBadge status={entity.status} />
                      </Row>
                    </VStack>
                    <VStack alignItems="start" w="full">
                      <Heading fontSize="md">Timeframes</Heading>
                      <Row>
                        <Label>Duration</Label>
                        <DeploymentDuration deployment={entity} />
                      </Row>
                      <Row>
                        <Label>Created at</Label>
                        <Text fontSize="sm">
                          {format(
                            new Date(entity.startedAt!),
                            "yyyy/MM/dd HH:mm:ss"
                          )}
                        </Text>
                      </Row>
                      <Row>
                        <Label>Completed at</Label>
                        <Text fontSize="sm">
                          {format(
                            new Date(entity.finishedAt!),
                            "yyyy/MM/dd HH:mm:ss"
                          )}
                        </Text>
                      </Row>
                    </VStack>
                    <VStack alignItems="start" w="full">
                      <Heading fontSize="md">Comments</Heading>
                      <Text fontSize="sm">{entity.comment}</Text>
                    </VStack>
                    {Boolean(entity.lambdaAbVariantId) && (
                      <Row>
                        <Label>Lambda A/B Test Variant</Label>
                        <Text fontSize="sm">{lambdaAbVariantEntity.name}</Text>
                      </Row>
                    )}
                  </VStack>
                </Flex>
              </Flex>

              <VStack flex={0.6} alignItems="stretch" spacing={4}>
                <Flex
                  borderRadius="md"
                  borderWidth={1}
                  flexDirection="column"
                  padding={4}
                  bg="white"
                >
                  <DeploymentSteps deployment={entity} />
                </Flex>

                <Flex
                  borderRadius="md"
                  borderWidth={1}
                  flexDirection="column"
                  padding={4}
                  bg="white"
                >
                  <VStack alignItems="start" w="full">
                    <Heading fontSize="md">Test Tags</Heading>
                    <HStack>
                      {entity.testTags.split("|").map((tag, index) => {
                        return (
                          <Badge key={index} colorScheme="green">
                            {tag}
                          </Badge>
                        )
                      })}
                    </HStack>
                  </VStack>
                </Flex>
              </VStack>
            </HStack>
          </Body>

          <Body fullWidth={true}>
            <FormSectionHeader>Debug</FormSectionHeader>

            <Accordion allowMultiple mt={3}>
              <AccordionItem>
                <AccordionButton justifyContent="space-between" pl={0}>
                  <Text>Test Results</Text>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  <DeploymentTestResultsContainer deploymentId={entity.id} />
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionButton justifyContent="space-between" pl={0}>
                  <Text>Config JSON</Text>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  <Box mt={3}>
                    <DeploymentConfig deploymentId={entity.id} />
                  </Box>
                </AccordionPanel>
              </AccordionItem>
              {previousDeployment && (
                <AccordionItem>
                  <AccordionButton justifyContent="space-between" pl={0}>
                    <Text>Changelog</Text>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <Box mt={3}>
                      <CompiledChangelogs
                        appId={appId}
                        deploymentIdFrom={previousDeployment.id}
                        deploymentIdTo={deploymentId}
                      />
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              )}
            </Accordion>
          </Body>
        </VStack>

        <Card
          minW="600px"
          flex={1}
          flexDirection="column"
          padding={4}
          boxShadow="none"
        >
          <FormSectionHeader>Deployment Logs</FormSectionHeader>
          <Box
            mt={3}
            p={2}
            backgroundColor="gray.700"
            borderRadius={3}
            color="green.200"
          >
            <XTerm
              ref={xtermRef}
              addons={xtermAddons}
              options={{
                rows: 30,
                theme: {
                  foreground: colors.green[200],
                  background: colors.gray[700],
                },
              }}
            />
          </Box>
        </Card>
      </HStack>
    </VStack>
  )
}

export default AppDeploymentDetailsScreen
